import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom'

//import Admin from './pages/admin'
//import Users from './pages/users'
//import Builder from './pages/builder'
//import Login from './pages/login'
//import Products from './pages/products'
const Admin = lazy(() => import('./pages/admin'))
const Users = lazy(() => import('./pages/users'))
const Builder = lazy(() => import('./pages/builder'))
const Login = lazy(() => import('./pages/login'))
const Products = lazy(() => import('./pages/products'))
import auth from './utils/auth'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      auth.getToken() !== null ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
)

export default function App() {
  return (
    <Router>
      <Suspense
        fallback={
          <div style={{ textAlign: 'center', marginTop: '20%' }}>
            <div>
              <img height={48} src="./logo.png" alt="Logo" />
            </div>
            <div className="loading-progress">Loading ...</div>
          </div>
        }
      >
        <PrivateRoute exact path="/" component={Builder} />
        <PrivateRoute exact path="/admin" component={Admin} />
        <PrivateRoute exact path="/admin/products" component={Products} />
        <PrivateRoute exact path="/admin/users" component={Users} />
        <Route exact path="/login" component={Login} />
      </Suspense>
    </Router>
  )
}
